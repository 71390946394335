import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import About from '../components/sections/About';
import JobOpportunity from '../components/sections/JobOpportunity';

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      <JobOpportunity topDivider className="illustration-section-02" />
      <About topDivider />
    </>
  );
}

export default Home;