import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const JobOpportunity = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container" id="JobOpportunity">
        <div className={innerClasses}>
          <h2 className="mt-0 mb-16 reveal-from-bottom ta-c" data-reveal-delay="200">特務團隊</h2>
          <div className={tilesClasses}>
            <div className="container">
              <p className="m-0 mb-32 center-content reveal-from-bottom ta-c" data-reveal-delay="400">
              請點選職務了解詳情 <br />或直接與我們聯繫 <a href="mailto:hr@dreamerfintech.com">hr@dreamerfintech.com</a>
              </p>
            </div>
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                  <a href="https://www.104.com.tw/job/7o1nu" target="104">IT系統管理師</a>
                  </p>
                  <p className="text-sm mb-0">
                  <a href="https://www.104.com.tw/job/7o1nx" target="104">系統分析師</a>
                  </p>
                  <p className="text-sm mb-0">
                  <a href="https://www.104.com.tw/job/7o1o4" target="104">軟體開發工程師 - Java</a>
                  </p>
                  <p className="text-sm mb-0">
                  <a href="https://www.104.com.tw/job/7o1o3" target="104">軟體開發工程師 - iOS</a>
                  </p>
                  <p className="text-sm mb-0">
                  <a href="https://www.104.com.tw/job/7o1o2" target="104">軟體開發工程師 - Android</a>
                  </p>
                  <p className="text-sm mb-0">
                  <a href="https://www.104.com.tw/job/7o1nz" target="104">前端工程師</a>
                  </p>
                  <p className="text-sm mb-0">
                  <a href="https://www.104.com.tw/job/7o1o5" target="104">測試工程師</a>
                  </p>
                  <p className="text-sm mb-0">
                  <a href="https://www.104.com.tw/job/7o1nw" target="104">自動化測試工程師</a>
                  </p>
                  <p className="text-sm mb-0">
                  <a href="https://www.104.com.tw/job/7o1o1" target="104">專案管理師</a>
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

JobOpportunity.propTypes = propTypes;
JobOpportunity.defaultProps = defaultProps;

export default JobOpportunity;